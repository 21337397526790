$(() => {
  $('#filters .collapsible-content .collapsible-content').each(function() {
    if ($(this).children().length > 10) {
      let maxHeight = $(this).find('.custom-checkbox').first().outerHeight(true) * 10; // max height is 10 times checkbox height
      $(this).height(maxHeight); // set max height to be 10x checkbox height (shows 10 first elements)
      $(this).parents('.form-group').siblings('.category-filter-display-more-wrap').show(); // show 'display more button' so it's possible to reveal all the filter checboxes
    }
  });

  // When clicking the 'display all button' add height 100% to the collapsible ajax_content
  // and hide the 'display all button'
  $('.category-filter-display-more-wrap').on('click', function(e) {
    e.preventDefault();
    $(this).siblings('.form-group').find('.collapsible-content').first().height('100%');
    $(this).hide();
  });

  // Go through all the current nodes and open their parents
  $('.category-menu li.current').each(function() {
    $(this).parents('li').addClass('open');
  });

  $('#search-dropdown').on('click', function () {
    setTimeout(function() {
      $('#nav-search-input').focus();
    }, 300);
  });

});

// Overwrite Shuup default product list update on the category page
const originalReloadProducts = window.reloadProducts;
function reloadProducts(filterString) {
  const $cont = $('#ajax_content');
  const $prods = $('.products-wrap');
  const $adminMenu = $('#admin-tools-menu');
  const adminMenuHeight = ($adminMenu.length > 0) ? $adminMenu.height() : 0;
  const top = ($prods.length > 0) ? $prods.offset().top : $cont.offset().top;

  $("html, body").animate({
    scrollTop: 0 + (top - adminMenuHeight),
  }, 300);

  // this is to ensure browser back/forward from different domain does a full refresh
  filterString += (filterString === '') ? '?' : '&';
  filterString += 'ajax=1';

  $('#category-product-loading-indicator').addClass('loading');

  $cont.load(location.pathname + filterString, function () {
    $('#category-product-loading-indicator').removeClass('loading');
  });
}
